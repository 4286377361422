<template>
    <div>
        <!-- 显示城市分布 -->
        <Row class="p-b-5">
            <i-col span="24" class="p-r-10">
                城市分布
                <a class="m-l-5" v-for="city in cityCount"
                  :class="(chooseCity===city.cityCode)?'choose-active':''" :key="city.cityCode " @click="handleChangeCityCode(city)">
                    {{city.cityName}}({{city.quantity }})
                </a>
            </i-col>
        </Row>
        <Row class="p-b-5">
            <i-col span="24" class="p-r-10">
                线路分布
                <a class="m-l-5" v-for="line in lineCount.filter(x=>x.cityCode===chooseCity||chooseCity===null)"
                  :class="(chooseLine===line.assetId)?'choose-active':''" :key="line.assetId " @click="handleChangeLine(line)">
                    {{line.assetName}}({{line.quantity }})
                </a>
            </i-col>
        </Row>
        <Row class="p-b-5">
            <i-col span="24" class="p-r-10">
                站点分布
                <a class="m-l-5" v-for="station in stationCount.filter(x=>(x.cityCode===chooseCity&&chooseLine===null )||(x.assetId===chooseLine)||(chooseCity===null&&chooseLine===null))"
                  :key="station.stationId " @click="handleChangeStation(station)">
                    {{station.stationName}}({{station.quantity }})
                </a>
            </i-col>
        </Row>

        <SvgStation ref="svgStation" :stationSvgModal.sync="svgModal.isShow" :orderId="orderId" :stationId="svgModal.stationId"
          :searchSchedules="[{startDate: svgModal.startDate, endDate: svgModal.endDate}]" :svgShowType="svgModal.svgShowType"
          showStationPortray
          @svg-finished="svgFinished" @handleChange3dMode="handleBeginRevice3d"/>

        <ThreeStation ref="threeStation" @three-finished="threeFinished" @handleChangeSvgMode="handleClickStation" />
    </div>
</template>

<script>
import SvgStation from '@/components/svg/SvgStation'
import ThreeStation from '@/components/svg/ThreeStation'
import { getProductDetailPageByOrder, getResourceCountByOrderId } from '@/api/order/productdetail'

export default {
  props: {
    orderId: {
      type: [Number, String],
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    customskuId: {
      type: Number
    }
  },
  components: {
    SvgStation, ThreeStation
  },
  data () {
    return {
      cityCount: [], // 城市分布
      chooseCity: null,
      lineCount: [], // 线路分布
      chooseLine: null,
      stationCount: [], // 站点分布

      svgModal: {
        isShow: false,
        stationName: '',
        stationId: 0,
        startDate: this.product.startDate,
        endDate: this.product.endDate,
        svgShowType: 3 // 3订单详情资源展示
      }
    }
  },
  mounted () {
    this.initpage()
  },
  methods: {
    initpage () {
      this.getStationCount()
    },
    svgFinished () {
      this.$refs.svgStation.initShowBrand()

      const schedule = JSON.stringify([{ startDate: this.product.startDate, endDate: this.product.endDate }])
      const resourceSearchData = {
        orderId: this.orderId,
        stationIds: this.svgModal.stationId,
        schedules: schedule,
        productId: this.customskuId ? this.product.curProductId : this.product.productId,
        customskuId: this.customskuId,
        pageSize: 200,
        pageNumber: 1
      }

      getProductDetailPageByOrder(resourceSearchData).then(res => {
        this.$refs.svgStation.tagResource(res.list)
        this.$refs.svgStation.choseSpinShow()
      })
    },
    getStationCount () {
      const stationQuery = {
        orderId: this.orderId,
        productId: this.customskuId ? this.product.curProductId : this.product.productId,
        customskuId: this.customskuId || undefined,
        schedules: JSON.stringify([{ startDate: this.product.startDate, endDate: this.product.endDate }]),
        priceType: this.product.priceType
      }
      getResourceCountByOrderId(stationQuery).then(res => {
        this.cityCount = []
        this.lineCount = []
        this.stationCount = res

        let cityBean = null
        let lineBean = null
        res.forEach(resource => {
          // 城市分布
          cityBean = this.cityCount.find(x => x.cityCode === resource.cityCode)
          if (!cityBean) {
            this.cityCount.push({
              cityCode: resource.cityCode,
              cityName: resource.cityName,
              quantity: resource.quantity
            })
          } else {
            cityBean.quantity += resource.quantity
          }
          // 线路分布
          lineBean = this.lineCount.find(x => x.assetId === resource.assetId)
          if (!lineBean) {
            this.lineCount.push({
              cityCode: resource.cityCode,
              cityName: resource.cityName,
              assetName: resource.assetName,
              assetId: resource.assetId,
              quantity: resource.quantity
            })
          } else {
            lineBean.quantity += resource.quantity
          }
        })
      })
    },
    handleChangeCityCode (city) {
      this.chooseLine = null
      if (this.chooseCity === city.cityCode) {
        this.chooseCity = null
      } else {
        this.chooseCity = city.cityCode
      }

      let allowAssetIds = [this.chooseLine]
      if (this.chooseLine === null) {
        allowAssetIds = this.lineCount.filter(x => x.cityCode === this.chooseCity || this.chooseCity === null).map(x => x.assetId)
      }
      this.$emit('on-update-city', this.chooseCity, allowAssetIds)
    },
    handleChangeLine (line) {
      this.chooseLine = (this.chooseLine === line.assetId) ? null : line.assetId
      let currentChooseCity = this.chooseCity
      if (!currentChooseCity) {
        currentChooseCity = line.cityCode
      }

      let allowAssetIds = [this.chooseLine]
      if (this.chooseLine === null) {
        allowAssetIds = this.lineCount.filter(x => x.cityCode === this.chooseCity || this.chooseCity === null).map(x => x.assetId)
      }
      this.$emit('on-update-city', currentChooseCity, allowAssetIds)
    },
    handleChangeStation (station) {
      this.svgModal.isShow = true
      this.svgModal.stationId = station.stationId
      this.svgModal.stationName = station.stationName
    },
    handleBeginRevice3d (stationId) {
      this.$refs.threeStation.initData(stationId)
    }
  },
  watch: {
    product (val) {
      this.initpage()
    }
  }
}
</script>
