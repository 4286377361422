
/**
 *
 * @param {Array} products
 */
export function buildCategories (products) {
  var categoryMap = new Map()
  products.forEach(product => {
    var category = categoryMap.get(product.goodId) || []
    category.push(product)
    categoryMap.set(product.goodId, category)
  })
  var result = []
  categoryMap.forEach((products, categoryId) => {
    var schedules = buildSchedules(products)
    result.push({
      category: products[0].goodName,
      categoryId: categoryId,
      schedules: schedules
    })
  })
  return result
}

/**
 *
 * @param {Array} products
 */
export function buildCategoriesOrder (products) {
  var categoryMap = new Map()
  products.forEach(product => {
    var category = categoryMap.get(product.categoryId) || []
    category.push(product)
    categoryMap.set(product.categoryId, category)
  })
  var result = []
  categoryMap.forEach((products, categoryId) => {
    var schedules = buildSchedules(products)
    result.push({
      category: products[0].categoryName,
      categoryId: categoryId,
      schedules: schedules
    })
  })
  return result
}

/**
 *
 * @param {Array} products
 */
function buildSchedules (products) {
  const scheduleMap = new Map()
  products.forEach(product => {
    const schedule = scheduleMap.get(product.startDate + '~' + product.endDate) || []
    schedule.push(product)
    scheduleMap.set(product.startDate + '~' + product.endDate, schedule)
  })
  return [...scheduleMap.values()]
}
