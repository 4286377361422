<template>
  <Table  stripe size="small" :columns="productColumns" :data="productData" border disabled-hover :span-method="handleProductSpan">
      <!-- 价格计算器 -->
      <template v-if="model === 3" slot-scope="{ row,index }" slot="discountPrice">
          <InputNumber v-model="row.totalSettlementPrice" :min="0" @on-change='updateTotalPrice(row.totalSettlementPrice,index)' ></InputNumber>
          <span class="text-red">{{ (row.totalUsePrice === 0 ? 0 : row.totalSettlementPrice / row.totalUsePrice * 10).toFixed(2) }}折</span>
      </template>
      <!-- 上刊设置数量 -->
      <template v-if="model === 4" slot-scope="{ row, index }" slot="mspQuantity">
          <InputNumber :min="0" v-model="row.mspQuantity" @on-change="handleSetting(row.mspQuantity,index)"></InputNumber>
          <span>{{ row.installUnit }}</span>
      </template>
  </Table>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { GetCurrentSchedule } from '@/utils/dateFormat'

export default {
  props: {
    products: {
      type: Array,
      required: true
    },
    model: {
      type: Number,
      required: true
    },
    discount: {
      type: Number
    },
    loading: {
      type: Boolean
    }
  },
  data () {
    return {
      productData: [],
      total: 0,
      isGift: false,
      scheduleColumSpan: [], // 档期列合并项
      mediumTypeColumSpan: [] // 媒体类型列合并项
    }
  },
  methods: {
    initProductPrice () { // 显示采购产品明细
      const getProductList = []
      this.products.forEach(product => {
        product.orderProductskuList.forEach(sku => {
          if (sku.freeQuantity > 0) {
            this.isGift = true
          }
          getProductList.push({
            schedule: GetCurrentSchedule(product.startDate, product.endDate),
            startDate: product.startDate,
            endDate: product.endDate,
            categoryId: product.categoryId,
            categoryName: product.categoryName,
            productskuId: sku.productskuId,
            productskuName: sku.productskuName,
            priceType: product.priceType, // 采购类型， 常规或者特惠
            productId: product.productId,
            tradeQuantity: sku.tradeQuantity,
            freeQuantity: sku.freeQuantity,
            quantity: sku.quantity,
            productUnit: sku.productUnit,
            totalSettlementPrice: sku.totalSettlementPrice,
            totalUsePrice: sku.totalUsePrice,
            usePrice: sku.showTotalUsePrice,
            numberUsePrice: sku.totalUsePrice / sku.quantity,
            mspQuantity: sku.mspQuantity,
            installPrice: sku.installPrice,
            producePrice: sku.producePrice,
            servicePrice: sku.installPrice + sku.producePrice,
            amount: sku.totalUsePrice + (sku.mspQuantity ? (sku.installPrice + sku.producePrice) * sku.mspQuantity : 0),
            totalSide: sku.totalSide, // 上刊数量
            installUnit: sku.installUnit // 上刊单位
          })
        })
      })

      this.productData = getProductList.sort(function (a, b) {
        return (a.schedule >= b.schedule && a.categoryId >= b.categoryId) ? 1 : -1
      })
      this.formatTable()
    },
    formatTable () {
      let exsitSchedule = null
      let exsitMedium = null
      this.scheduleColumSpan = []
      this.mediumTypeColumSpan = []

      for (let index = 0; index < this.productData.length; index++) {
        // 获取合并的档期项
        exsitSchedule = this.scheduleColumSpan.find(x => x.schedule === this.productData[index].schedule)
        if (exsitSchedule) {
          exsitSchedule.end += 1
        } else {
          this.scheduleColumSpan.push({
            schedule: this.productData[index].schedule,
            start: index,
            end: index + 1
          })
        }

        // 获取合并的媒体类型项
        exsitMedium = this.mediumTypeColumSpan.find(m => m.categoryName === this.productData[index].categoryName && m.schedule === this.productData[index].schedule)
        if (exsitMedium) {
          exsitMedium.end += 1
        } else {
          this.mediumTypeColumSpan.push({
            categoryName: this.productData[index].categoryName,
            schedule: this.productData[index].schedule,
            start: index,
            end: index + 1
          })
        }
      }
    },
    handleProductSpan ({ row, column, rowIndex, columnIndex }) {
      // 档期列合并
      if (columnIndex === 0) {
        for (let index = 0; index < this.scheduleColumSpan.length; index++) {
          if (rowIndex === this.scheduleColumSpan[index].start) {
            return [this.scheduleColumSpan[index].end - this.scheduleColumSpan[index].start, 1]
          } else if (rowIndex < this.scheduleColumSpan[index].end) {
            return [0, 0]
          }
        }
      }
      // 媒体类型列合并
      if (columnIndex === 1) {
        for (let index = 0; index < this.mediumTypeColumSpan.length; index++) {
          if (rowIndex === this.mediumTypeColumSpan[index].start) {
            return [this.mediumTypeColumSpan[index].end - this.mediumTypeColumSpan[index].start, 1]
          } else if (rowIndex < this.mediumTypeColumSpan[index].end) {
            return [0, 0]
          }
        }
      }
    },
    buildCategory () {
      let products = []
      const total = { usePrice: 0, settlementPrice: 0 }
      if (this.discount >= 0) {
        this.productData.forEach(product => {
          const totalSettlementPrice = (product.totalUsePrice * this.discount / 10).toFixed(2) * 1
          total.settlementPrice += totalSettlementPrice
          total.usePrice += product.totalUsePrice
          products.push(Object.assign({}, product, { discount: this.discount, totalSettlementPrice: totalSettlementPrice }))
        })
      } else if (this.discount === -1) {
        products = this.productData.map(x => {
          total.settlementPrice += x.totalSettlementPrice
          total.usePrice += x.totalUsePrice
          const discount = x.totalUsePrice === 0 ? 0 : x.totalSettlementPrice / x.totalUsePrice * 10
          return Object.assign({}, x, { discount: discount.toFixed(2) })
        })
      }
      this.$emit('on-computed', total)
    },
    updateTotalPrice (value, index) {
      if (index || index === 0) {
        this.productData[index].totalSettlementPrice = value
      }

      const total = { usePrice: 0, settlementPrice: 0 }

      this.productData.forEach(product => {
        total.settlementPrice += product.totalSettlementPrice
        total.usePrice += product.totalUsePrice
      })
      this.$emit('on-computed', total)
    },
    handleSetting (val, index) {
      this.productData[index].mspQuantity = (val === null) ? 0 : val
    }
  },
  computed: {
    productColumns () {
      let Focus = [{ title: '发布档期', key: 'schedule' },
        { title: '媒介类型', width: 110, align: 'center', key: 'categoryName' },
        {
          title: '单品类型',
          render: (h, data) => {
            if (data.row.priceType === 0) {
              return h('div', [
                h('span', data.row.productskuName),
                h('tag', { props: { color: '#ed4014' } }, '特惠')
              ])
            } else {
              return h('span', data.row.productskuName)
            }
          }
        }
      ]
      let purchaseItem = {
        title: '采购数量',
        width: 110,
        align: 'center',
        render: (h, data) => {
          return h('span', data.row.tradeQuantity + ' ' + data.row.productUnit)
        }
      }
      if (this.isGift) {
        purchaseItem = {
          title: '采购数量',
          width: 110,
          align: 'center',
          children: [
            {
              title: '常规',
              align: 'center',
              render: (h, data) => {
                return h('span', data.row.tradeQuantity + ' ' + data.row.productUnit)
              }
            },
            {
              title: '赠送',
              align: 'center',
              render: (h, data) => {
                if (data.row.freeQuantity > 0) {
                  return h('p', { }, data.row.freeQuantity + ' ' + data.row.productUnit)
                }
                return h('span', data.row.freeQuantity + ' ' + data.row.productUnit)
              }
            }
          ]

        }
      }
      Focus.push(purchaseItem)
      switch (this.model) {
        case 1:
          Focus = Focus.concat([
            {
              title: '刊例价',
              width: 110,
              render: (h, data) => {
                return h('span', data.row.usePrice + ' / ' + data.row.productUnit)
              }
            },
            {
              title: '上刊制作费',
              width: 110,
              align: 'center',
              render: (h, data) => {
                return h('span', toMoney(data.row.servicePrice))
              }
            },
            {
              title: '刊例价小计',
              align: 'center',
              width: 110,
              render: (h, data) => {
                return h('span', toMoney(data.row.amount))
              }
            }])
          break
        case 2:
          Focus = Focus.concat([{
            title: '刊例总价',
            render: (h, data) => {
              return h('span', toMoney(data.row.totalUsePrice))
            }
          }
          // {
          //   title: '折扣小计',
          //   render: (h, data) => {
          //     if (this.discount === -1) {
          //       return h('span', toMoney((data.row.totalSettlementPrice).toFixed(2) * 1))
          //     } else {
          //       return h('span', toMoney((data.row.totalUsePrice * this.discount / 10).toFixed(2) * 1))
          //     }
          //   }
          // }
          ])
          break
        case 3:
          Focus = Focus.concat([{
            title: '刊例总价',
            render: (h, data) => {
              return h('span', toMoney(data.row.totalUsePrice))
            }
          }, {
            title: '折扣小计',
            width: 180,
            slot: 'discountPrice'
          }
          ])
          break
        case 4:
          Focus = Focus.concat([
            {
              title: '上刊制作单价',
              width: 120,
              render: (h, data) => {
                return h('span', toMoney(data.row.installPrice + data.row.producePrice) + ' / ' + data.row.installUnit)
              }
            },
            { title: '设置上刊制作数量', width: 180, slot: 'mspQuantity' }
          ])
          break
      }
      return Focus
    }
  },
  mounted () {
    this.initProductPrice()
    if (this.discount) {
      // 延迟统计总价，等待产品类型循环完毕
      setTimeout(() => {
        this.buildCategory()
      }, 500)
    }
  },
  watch: {
    products () {
      this.initProductPrice()
    },
    discount () {
      this.buildCategory()
    },
    loading (val) {
      if (val) {
        const saveData = []
        this.productData.forEach(item => {
          saveData.push({
            startDate: item.startDate,
            endDate: item.endDate,
            productId: item.productId,
            productskuId: item.productskuId,
            quantity: item.mspQuantity
          })
        })
        this.$emit('on-updata', saveData)
      }
    },
    model (val) {
      if (this.model === 3) {
        this.updateTotalPrice()
      }
    }
  }
}
</script>
