<template>
  <div>
      <h3>媒体发布费</h3>
      <OrdeBasicrDetail :products="products" :model='1' />
      <Row class="p-t-5">
        <i-col span="24" class="text-right">
          <span class="m-r-20">
            刊例价合计： {{formatMoney(summaryPrice.totalUsePrice)}}
          </span>
          <br/>
          <span class="m-r-20">
            服务费合计： {{formatMoney(summaryPrice.servicePirce)}}
          </span>
        </i-col>
      </Row>
      <hr />
      <h3 class="p-t-10">其他费用</h3>
      <Row>
          <i-col span="24" class="right">
              <Table  stripe size="small"  style="width:400px;float: inherit;" :row-class-name="rowClassName" :columns="serviceColumns" :data="serviceData" border></Table>
          </i-col>
      </Row>
      <Row class="p-t-5">
        <i-col span="24" class="text-right p-r-20">
          合计：{{formatMoney(summaryPrice.discountPrice)}}
        </i-col>
      </Row>
      <hr />
      <Row>
        <i-col span="12">
          <span class="remark">PS：服务费为固定费用，最终价格以实际上刊设置为准</span>
        </i-col>
        <i-col span="12" class="text-right text-16 p-r-20">
          订单总价：<b class="text-orange">{{formatMoney(summaryPrice.finalUsePrice + summaryPrice.servicePirce)}}</b>
        </i-col>
      </Row>

      <Spin fix size="large" v-if="loading">
          <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
          <div>数据加载中...</div>
      </Spin>
  </div>

</template>

<script>
import { getSummaryPrice } from '@/api/order/orderprice'
import { toMoney } from '@/utils/wnumb_own'
import OrdeBasicrDetail from '../common/OrdeBasicrDetail'

export default {
  props: {
    products: {
      type: Array,
      required: true
    },
    orderId: {
      type: Number,
      required: true
    }
  },
  components: {
    OrdeBasicrDetail
  },
  data () {
    return {
      loading: false,
      serviceColumns: [
        {
          title: '明细类别',
          width: 180,
          render: (h, params) => {
            return h('span', params.row.productName + ' - ' + params.row.ruleTypeName)
          }
        },
        { title: '刊例折扣', align: 'center', key: 'value' },
        {
          title: '费用',
          width: 130,
          align: 'right',
          render: (h, params) => {
            return h('span', this.formatMoney(params.row.usePrice))
          }
        }
      ],
      serviceData: [],
      summaryPrice: {
        servicePirce: 0, // 服务费合计
        totalUsePrice: 0, // 刊例合计
        finalUsePrice: 0, // 真实成交刊例
        discountPrice: 0 // 折扣费用
      }
    }
  },
  mounted () {
    this.initServicePrice()
  },
  watch: {
    orderId: function () {
      this.initServicePrice()
    },
    products (val) {
      this.initOrderDetialPrice()
    }
  },
  methods: {
    rowClassName (row, index) {
      if (index % 2 === 0) {
        return 'ivu-table-stripe-even'
      } else return 'ivu-table-stripe-odd'
    },
    initServicePrice () { // 显示增值服务费明细
      getSummaryPrice({ orderId: this.orderId }).then(res => {
        this.serviceData = res.rulePriceResultList

        this.summaryPrice.totalUsePrice = res.totalUsePrice
        this.summaryPrice.finalUsePrice = res.finalUsePrice
        this.summaryPrice.discountPrice = res.finalUsePrice - res.totalUsePrice
        this.loading = false
      })
    },
    initOrderDetialPrice () {
      // 获取服务费合计
      let orderamount = 0
      this.products.forEach(product => {
        product.orderProductskuList.forEach(sku => {
          orderamount += (sku.installPrice + sku.producePrice)
        })
      })
      this.summaryPrice.servicePirce = orderamount
    },
    formatMoney (number) {
      return toMoney(number)
    }
  }
}
</script>
