<template>
  <div>
    <Tabs v-model="currentTabPane" size="small" class="text-white">
        <TabPane v-for="category in categories"  :key="category.categoryId" :label="category.category === 'detail'?'订单详情':category.category+'采购详情'" :name="category.category">
        </TabPane>
    </Tabs>

    <template v-if="orderId">
      <purchaseInfo v-if="currentTabPane==='detail'" :orderId="orderId" :products="products"/>
      <div v-else>
        <component :is="getTabpaneComponent(curCategory.categoryId)" :schedules="curCategory.schedules" :orderId="orderId" @on-detatil-postsite="handleShowDetailPostSite"></component>
      </div>
    </template>
  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
import purchaseInfo from '@/components/order/details/PurchaseInfoAdx'
import plat from '../detailComponents/Plat'

import { buildCategoriesOrder } from '@/utils/orderUtils'
import { getProductPrice } from '@/api/order/orderprice'

export default {
  mixins: [sysMixins],
  components: {
    purchaseInfo, plat
  },
  data () {
    return {
      currentTabPane: 'detail',
      categories: [],
      products: [],
      curCategory: null
    }
  },
  mounted () {
    this.initPurchaseList()
  },
  methods: {
    initPurchaseList () {
      this.spinShow = true
      this.products = []
      // 获取订单的价格清单和档期明细
      getProductPrice({ orderId: this.orderId }).then(res => {
        this.products = res
        const categories = buildCategoriesOrder(res)
        categories.unshift({
          category: 'detail',
          categoryId: 0
        })
        this.categories = categories.map(x => {
          return Object.assign({}, x, { shown: false })
        })
        this.spinShow = false
      })
    },
    getTabpaneComponent (category) {
      this.$store.commit('set_adx_categoryId', category)
      // 目前暂时只支持12封灯箱
      return 'plat'
    },
    handleShowDetailPostSite (stations) { // 地图显示投放详情
      this.$store.commit('set_adx_update_map', new Date())
      this.$store.commit('set_adx_stations', stations)

      // 更新显示线路
      const assetIds = []
      stations.forEach(element => {
        if (!assetIds.includes(element.assetId)) {
          assetIds.push(element.assetId)
        }
      })

      this.$store.commit('set_adx_show_assets', assetIds)
    }
  },
  computed: {
    orderId () {
      return this.$store.state.adx.orderId
    }
  },
  watch: {
    currentTabPane: function () {
      if (this.currentTabPane === 'detail') {
        this.setShowMap(false)
        // this.setBottomHeight('90%')
        this.handleShowDetailPostSite([])
      } else {
        this.curCategory = this.categories.find(x => x.category === this.currentTabPane)
        this.setShowMap(true)
        this.setMapComponent('centerComponent')
        // this.setBottomHeight('300px')
        if (this.currentTabPane !== '平面媒体') { // 清空地图选中点位
          this.handleShowDetailPostSite([])
        }
      }
    }
  }
}
</script>
