import request from '@/utils/requestV2'
const qs = require('qs')

/**
 *获取订单方案（录合同时）价格
 * @param {object} data
 */
export function getPlanPrice (data) {
  return request({
    url: '/ooh-order/v1/orderprice/getplanprice',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
 *获取订单产/单品价格
 * @param {object} data
 */
export function getProductPrice (data) {
  return request({
    url: '/ooh-order/v1/orderprice/getproductprice',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
 *获取订单价格汇总
 * @param {object} data
 */
export function getSummaryPrice (data) {
  return request({
    url: '/ooh-order/v1/orderprice/getsummaryprice',
    method: 'post',
    data: qs.stringify(data)
  })
}
